import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckYesIcon from '../../assets/icons/check-yes.svg';
import CheckNoIcon from '../../assets/icons/check-no.svg';
import WarningIcon from '../../assets/icons/warning.svg';
import styles from './Message.module.scss';

const SUCCESS = 'success';
const ERROR = 'error';
const WARNING = 'warning';

const Message = ({ variant, inline, children }) => (
  <div className={classnames(styles.root, styles[variant], inline && styles.inline)}>
    {variant === SUCCESS && <CheckYesIcon variant="roundSolid" className={styles.icon} />}
    {variant === ERROR && <CheckNoIcon variant="roundSolid" className={styles.icon} />}
    {variant === WARNING && <WarningIcon className={styles.icon} />}
    <div className={styles.text}>{children}</div>
  </div>
);

Message.propTypes = {
  variant: PropTypes.oneOf([SUCCESS, ERROR, WARNING]).isRequired,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Message.defaultProps = {
  inline: false,
};

export default Message;
