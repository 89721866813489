import { cn } from '@carvertical/utils/styling';
import Head from 'next/head';
import { Container, Text, Heading, Stack, IconButton } from '@carvertical/ui';
import * as seo from 'utils/seo';
import { Copyright } from 'modules/shared/components/footer';
import Logo from 'modules/shared/components/header/Logo';
import { Link } from 'components/common/Link';

type AuthPageProps = {
  title: string;
  children: React.ReactNode;
  description?: string;
  invalid?: boolean;
  panelClassName?: string;
  backHref?: string;
};

const AuthPage = ({
  children,
  description,
  invalid,
  backHref,
  panelClassName,
  title,
}: AuthPageProps) => (
  <div
    className={cn(
      'flex flex-1 flex-col items-center gap-5 bg-grey-50 py-5',
      invalid && 'motion-safe:animate-shake',
    )}
  >
    <Head>
      <meta name="robots" content="noindex, nofollow" />

      {title && <title>{seo.formatTitle(title)}</title>}
    </Head>

    <header>
      <Logo priority />
    </header>

    <main className="w-full">
      <Container className="flex h-full justify-center">
        <section
          className={cn(
            'relative w-full rounded-l bg-white p-3 shadow-s sm:w-64 sm:px-12',
            panelClassName,
          )}
        >
          {backHref && (
            <IconButton
              as={Link}
              className="md:absolute md:left-3 md:top-3"
              variant="outline"
              href={backHref}
              label="Go back"
              size="s"
              icon="chevron-left"
            />
          )}

          <Stack gap={4} crossAxisAlign="stretch">
            <Stack gap={2} crossAxisAlign="center">
              <Heading as="h1" variant="s" align="center">
                {title}
              </Heading>

              {description && (
                <Text variant="s" align="center">
                  {description}
                </Text>
              )}
            </Stack>

            {children}
          </Stack>
        </section>
      </Container>
    </main>

    <footer>
      <Container>
        <Copyright />
      </Container>
    </footer>
  </div>
);

export { AuthPage };
export type { AuthPageProps };
